.background,
.modal {
  height: calc(100vh);
  left: 0;
  // margin-top: 81px;
  position: fixed;
  top: 0;
  width: 100vw;
}

.background {
  background: rgba(#000, 0.5);
  z-index: 88;
}

.modal {
  display: flex;
  overflow-y: auto;
  padding: 36px 0;
  position: fixed;
  z-index: 1450;
}

.wrapper {
  width: 100%;
  max-width: 560px;
  background: white;
  border-radius: 4px;
  margin: auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 20px rgba(27, 42, 61, 0.06);
  @media (max-width: 676px) {
    width: 90%;
  }
  .body {
    display: flex;
    width: 100%;

    .form {
      width: 100%;

      .formWrapper {
        padding: 20px 30px 0;
        width: 100%;
        background: #fff;

        .uploadWrapper {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          div {
            width: 65%;
          }
        }
      }

      .warning {
        color: red;
      }

      label {
        display: block;
        margin-bottom: 8px;
      }
    }

    .selectOption {
      width: 100%;
      border: 1px solid #ced4da;
      border-radius: 4px;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &.error {
        border: 1px solid rgb(255, 62, 62);
      }

      & > div {
        box-shadow: none !important;
      }
    }

    .form label {
      font-family: "Inter", serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #858e99;
    }

    .form input,
    .form textarea .form select {
      font-family: "Inter", serif;
      border: 1px solid #e3e5e9;
      border-radius: 4px;
      height: 48px;
      font-size: 16px;
      line-height: 48px;
      padding: 0 16px;

      &:placeholder {
        color: #e6e7eb;
      }
    }

    .form textarea {
      min-height: 84px;
      max-height: 150px;
    }

    .inputGroup {
      width: 100%;
      position: relative;
      box-sizing: border-box;

      label {
        color: #858e99;

        span {
          opacity: 0.5;
          margin-left: 5px;
        }
      }
    }

    .button {
      width: 100%;
      display: block;
      height: 48px;
      background: linear-gradient(180deg, #1263de 0%, #448fff 76.56%);
      border-radius: 4px;
      font-family: "Poppins", serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      color: white;
      padding: 12px 16px;
      border: none;

      &:hover {
        background: linear-gradient(180deg, #1263de 0%, #448fff 76.56%);
        color: white;
        opacity: 0.8;
      }
    }

    .buttonCancel {
      background: white;
      color: #1f85cf;
      border: 1px solid #1f85cf;
    }

    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      background: linear-gradient(180deg, #1263de 0%, #448fff 76.56%);
      border-bottom: 1px solid #e3e5e9;
      padding: 0 15px;
      height: 73px;
      border-radius: 4px 4px 0 0;

      .close {
        width: 35px;
        height: 35px;
        border-radius: 3px;
        border: solid 1px white;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
      }
    }

    .headerTitle {
      font-family: "Poppins", serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0;
      color: white;
    }

    .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #1b2a3d;

      span {
        font-weight: 400;
        color: silver;
      }
    }

    .footerNavigation {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin: 20px 0px;
      gap: 20px;

      .button {
        font-weight: 600;
        width: min-content;
      }
    }

    .spanError {
      color: red;
      position: absolute;
      bottom: -20px;
      left: 0;
      display: inline-block;
      opacity: 0.7;
      font-size: 12px;
    }

    .inputValue {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 16px;
      @media (max-width: 676px) {
        display: block;
      }
    }
  }
}
