.bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 1111;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .wrapper {
    width: 565px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid #e6e7eb;
    background: white;
    position: relative;
    z-index: 10;

    .header {
      width: 100%;
      height: 73px;
      background: linear-gradient(180deg, #1263de 0%, #448fff 76.56%);
      color: white;
      display: flex;
      justify-content: space-between;
      padding: 20px;
      border-radius: 4px;

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }

      .close {
        width: 28px;
        height: 28px;
        border-radius: 3px;
        border: solid 1px white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .body {
      padding: 30px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      p {
        line-break: anywhere;
        margin-top: 1rem;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
      }
      div {
        span:first-letter {
          text-transform: uppercase;
        }
      }

      .item {
        font-weight: bold;
        margin-top: 10px;
      }
    }

    .button {
      width: 100%;
      padding: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      button {
        background: #f00000;
        padding: 8px 12px;
        color: white;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        border: none;
        border-radius: 4px;
      }
    }
  }
}
@media (max-width: 767px) {
  .wrapper {
    max-width: 343px;
    max-height: 209px;
    .header {
      max-height: 50px;
      padding: 0 5px 20px;

      .close {
        position: relative;
        bottom: 8px;
      }
    }
    .body {
      max-height: 90px;
    }
    .buttonMob {
      width: 100%;
      padding: 18px;
      button {
        padding: 8px 12px;
        color: white;
        width: 100%;
        background: #f00000;
        font-family: "Poppins", serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        border: none;
        border-radius: 4px;
      }
    }
  }
}
