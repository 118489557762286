.headline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 52px;
  position: relative;

  figure {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;

    p {
      font-family: 'Inter';
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      border: 2px solid rgba(31, 133, 207, 0.25);
      ;
      background: rgba(31, 133, 207, 0.05);
      box-sizing: content-box;
      color: rgba(31, 133, 207, 0.5);
      margin-bottom: 0;
    }

    figcaption {
      font-family: 'Inter';
      font-size: 14px;
      line-height: 20px;
      color: rgba(31, 133, 207, 0.5);
      margin-bottom: 0;
      position: absolute;
      margin-top: 42px;
    }
  }

  span {
    background: rgba(31, 133, 207, 0.25);
    height: 2px;
    flex: 1;

    &:first-of-type {
      width: 10%;
      flex: initial;
    }

    &:last-of-type {
      width: 10%;
      flex: initial;
    }
  }

  &.hideFirst {
    figure {
      &:nth-of-type(1) {
        visibility: hidden;
        opacity: 0;
      }
    }

    span {

      &:nth-of-type(1),
      &:nth-of-type(2) {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  &.hideThirds {
    figure {
      &:nth-of-type(3) {
        visibility: hidden;
        opacity: 0;
      }
    }

    span {

      &:nth-of-type(3),
      &:nth-of-type(4) {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  &.lastThirds {
    span {
      &:nth-of-type(4) {
        opacity: 0;
      }
    }
  }

  &.activeFirst {
    figure {
      &:nth-of-type(1) {
        p {
          background: #fff;
          border-color: #FEAB3D;
          color: #FEAB3D;
        }

        figcaption {
          color: #FEAB3D;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
        }
      }
    }

    span {

      &:nth-of-type(1),
      &:nth-of-type(2) {
        background: #FEAB3D;
      }
    }
  }

  &.completeFirst {
    figure {
      &:nth-of-type(1) {
        p {
          background: #FEAB3D;
          border-color: #FEAB3D;
          color: #FEAB3D;
          color: #fff;
        }

        figcaption {
          color: #112538;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    span {

      &:nth-of-type(1),
      &:nth-of-type(2) {
        background: #FEAB3D;
      }
    }
  }

  &.activeSecond {
    figure {
      &:nth-of-type(2) {
        p {
          background: #fff;
          border-color: #FEAB3D;
          color: #FEAB3D;
        }

        figcaption {
          color: #FEAB3D;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
        }
      }
    }

    span {
      &:nth-of-type(3) {
        background: #FEAB3D;
      }
    }
  }
}