.content {
  display: flex;
  height: 100%;

  .background {
    // background-image: url('../../../../assets/img/initial_setup.png'); // CB build is not working if this image will uncomment
    background-size: cover;
    background-position: center;
    width: 60%;
  }

  .wrapper {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form {
    width: 350px;
    text-align: center;

    .title {
      font-size: 28px;
      line-height: 30px;
      font-weight: 500;
      color: #000000;
      margin-bottom: 44px;
    }

    .group {
      margin-bottom: 44px;
    }

    .radio {
      margin-bottom: 32px;
    }
  }
}
