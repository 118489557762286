.background,
.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.background {
  background-color: rgba(#000000, 0.5);
  height: 100vh;
  user-select: none;
}

.modal {
  display: flex;
  overflow-y: auto;
  padding: 2px 0;
}

.wrapper {
  width: 100%;
  max-width: 535px;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: auto;
  z-index: 101;

  .header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 65px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 4px 4px 0 0;

    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: black;
    }
    .close {
      display: flex;
      // flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px;
      gap: 6px;
      width: 28px;
      height: 28px;
      background: #ecf3f7;
      border-radius: 6px;
      outline: none;
      border: none;
      svg {
        width: 27px;
        height: 27px;
      }
    }
  }

  .body {
    padding: 14px 20px;
    width: 100%;
    // p {
    //   font-family: "Inter";
    //   font-style: normal;
    //   font-weight: 400;
    //   font-size: 14px;
    //   line-height: 20px;
    //   color: #878787;
    //   max-width: 435px;
    //   flex-wrap: wrap;
    // }
    .scoringValue {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #878787;
      }
      .Aibtn {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #1271a6;
        border: none;
        background-color: transparent;
        margin-bottom: 5px;
      }
    }

    textarea {
      // resize: none;
      width: 100%;
      height: 80px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 6px;
      padding: 10px;
      font-family: "Inter";
      font-style: italic;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    button {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: #ffffff;
      padding: 12px 20px;
      width: 55px;
      height: 36px;
      background: #1271a6;
      border-radius: 4px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
