.wrapper {
  position: relative;
  background-color: #f4f4f4;
  padding: 1rem 0rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 20px;
  background-color: #ddd;
  animation-duration: 6s;
  animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
  animation-iteration-count: infinite;

  div {
    height: 6px;
    width: 100%;
    background-color: #fff;
    opacity: 0.8;
  }

  &.first {
    animation-name: loading_first;
  }

  &.second {
    animation-name: loading_second;
  }

  &.third {
    animation-name: loading_third;
  }

  &.fourth {
    animation-name: loading_fourth;
  }

  &:not(.fourth) {
    margin-bottom: 12px;
  }
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  animation-duration: 6s;
  animation-timing-function: ease-in-out /* cubic-bezier(0.6, 0, 0.4, 1) */;
  animation-iteration-count: infinite;
  animation-name: text_animation;
  animation-delay: 3s;
  opacity: 0;
  top: 0;

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #879091;
    text-align: center;
    max-width: 75%;
    width: 100%;
  }
}

@keyframes loading_first {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  12% {
    opacity: 1;
    transform: translateX(-95%);
  }
  24% {
    transform: translateX(0);
  }
  36% {
    transform: translateX(60%);
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes loading_second {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  12% {
    opacity: 1;
    transform: translateX(-90%);
  }
  24% {
    transform: translateX(0);
  }
  36% {
    transform: translateX(75%);
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes loading_third {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  12% {
    opacity: 1;
    transform: translateX(-76%);
  }
  24% {
    transform: translateX(0);
  }
  36% {
    transform: translateX(90%);
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes loading_fourth {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  12% {
    opacity: 1;
    transform: translateX(-60%);
  }
  24% {
    transform: translateX(0);
  }
  36% {
    transform: translateX(95%);
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes text_animation {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  45% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}
