.body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  // height: calc(100vh - 80px);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;

  transition: all .3s;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;

  &.active {
    pointer-events: initial;
    opacity: 1;
    visibility: visible;
  }
}

.background {
  backdrop-filter: blur(2px);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.25);
}

.modal {
  width: 640px;
  height: calc(100vh - 80px);
  max-height: 480px;
  position: relative;
  z-index: 2;

  iframe {
    box-shadow: 0px 0px 21px -3px rgba(0, 0, 0, 0.75);
    border-radius: 4px;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }

  transition: all .2s;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 25%) scale(0.8);

  &.modalActive {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0) scale(1);
  }

  :global(.ant-spin-nested-loading),
  :global(.ant-spin-container) {
    height: 100%;
  }

  :global(.ant-spin-blur) {
    opacity: 1;
  }

  :global(.ant-spin-nested-loading > div > .ant-spin) {
    max-height: initial;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border: none;
  background-color: transparent;
  padding: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  margin-left: 13px;
  margin-bottom: 12px;
  z-index: 99910;
  transition: transform .2s;

  @media (max-width: 1300px) {
    margin-left: 9px;
    margin-bottom: 6px;
  }

  figure {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 48px;
    height: 48px;
    background: #57E2EB;
    border-radius: 50%;
  }

  &:hover {
    transform: scale(1.15)
  }
}
