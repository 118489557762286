.form {
    width: 536px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: white;
    padding: 0px 30px;
    position: absolute;
    right: 0;
    top: 0;

    label {
        display: block;
        margin-bottom: 10px;
    }
}

.selectOption {
    border: 1px solid #E3E5E9;
    border-radius: 2px;
    width: 100%;

    &.error {
        border: 1px solid rgb(255, 62, 62);
    }
}

.form label {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #858E99;
}

.inputGroup {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    box-sizing: border-box;
    label {
        color: #858E99;

        span {
            opacity: .5;
            margin-left: 5px;
        }
    }
}

.button {
    width: 100%;
    display: block;
    height: 44px;
    background: #1F85CF;
    border-radius: 4px;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: white;
    padding: 12px 16px;
}

.buttonCancel {
    background: white;
    color: #1F85CF;
    border: 1px solid #1F85CF;
}

.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-top: 20px;
}

.headerTitle {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 28px;
}

.title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #1B2A3D;

    span {
        font-weight: 400;
        color: silver;
    }
}

.footerNavigation {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 20px 0px;
    gap: 20px;
    margin-top: auto;

    .button {
        font-weight: 600;
        width: min-content;
    }
}

.spanError {
    color: red;
    position: absolute;
    bottom: -20px;
    left: 0;
    display: inline-block;
    opacity: .7;
    font-size: 12px;
}