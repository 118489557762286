.goal {
  width: 100%;
  padding: 0 16px;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .goalBox {
    display: flex;
    flex-direction: column;
    background-color: #116595;
    border-radius: 2px;
    padding: 6px 24px 12px;
    height: 100px;

    .goalBoxHead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 4px;

      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0;
        color: #fff;
      }
    }

    .goalBoxBody {
      display: flex;
      flex: 1;
      width: calc(100% + 10px);
    }

    .goalBoxContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      column-gap: 10px;
    }

    .goalRangeContent {
      width: 100%;
      overflow: hidden;
      padding: 0 10px;

      .rangePath {
        background: #F5F5F5;
        border-radius: 100px;
        width: 100%;
        height: 8px;
      }

      .rangeHead {
        background: #fff;
        box-shadow: 0px 2px 6px -1px rgba(16, 24, 40, 0.2), 0px 1px 4px -2px rgba(16, 24, 40, 0.2);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        outline: none;
      }

      .rangeSteps {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        span {
          color: #A0C6DB;
          font-size: 12px;
          line-height: 20px;
          font-family: 'Inter';
        }
      }

      .rangeValues {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 8px 0 0 0;
        padding: 0;

        li {
          font-family: 'Inter';
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #fff;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 1;

          &:nth-of-type(2) {
            margin: 0 7px;
          }

          span {
            font-weight: 400;
          }
        }
      }
    }

    .card {
      width: 70px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 8px;
      margin-right: 8px;

      .title {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #A0C6DB;
        padding: 0;
        margin: 0;
      }

      .unitText {
        font-family: 'Inter';
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-transform: capitalize;
      }
    }

    .inputGroup {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;

      .goalTitle {
        font-family: 'Inter';
        color: #A0C6DB;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        margin-right: 3px;
      }

      input {
        font-family: 'Inter';
        width: 100%;
        min-width: 70px;
        background-color: #fff;
        border: none;
        padding: 4px 8px;
        appearance: textfield;
        border: 1px solid #E5E5E5;
        font-weight: 500;
        color: #171717;
        font-size: 14px;
        line-height: 20px;

        &.error {
          background: #ff9191;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          appearance: none;
          -webkit-appearance: none;
          margin: 0;
        }
      }

      .unitTitle {
        font-family: 'Inter';
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  button {
    background-color: transparent;
    border: none;
    padding: 0;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #71AACA;

    &.actionBtn {
      svg {
        margin-right: 6px;
      }
    }

    &.active {
      font-weight: 600;
      color: #fff;
    }
  }
}
