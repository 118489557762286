.option {
  display: flex;
  justify-content: space-between;
  padding: 11px 0 11px 7px;
  align-items: center;
  width: 100%;
  position: relative;

  &.winner1 {
    background: linear-gradient(
        0deg,
        rgba(254, 171, 61, 0.1),
        rgba(254, 171, 61, 0.1)
      ),
      #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 11px 16px;
    align-items: center;
    width: 100%;
    position: relative;
  }

  .nameWrapper {
    display: flex;
    align-items: center;
    width: 100%;

    .checkbox {
      display: flex;
      align-items: center;

      span {
        padding: 0 7px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #1b2a3d;
      }
    }

    .img {
      background: #e7f6fe;
      border-radius: 4px;
      padding: 0;
      border: none;

      img {
        width: 42px;
        height: 42px;
        object-fit: cover;
        border-radius: 4px;
      }
    }

    .titleWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      width: 100%;

      span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .title {
        flex: 1;
        font-family: "Inter", serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        display: -webkit-inline-box;
        max-height: 100%;
        // max-width: 200px;
        vertical-align: middle;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        &.winner {
          color: #feab3d;
          font-weight: 500;
        }
      }
    }
  }

  .popover {
    color: #888888;
    font-size: 20px;
  }

  .deleteModalContainer {
    z-index: 1500;
    position: fixed;
    top: 0px;
    left: 0px;
  }
}

.wrapper {
  position: relative;
  z-index: 10;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
}

.input {
  height: 40px;
  padding-left: 10px;
  width: 100%;
  border-radius: 2px;
  margin-left: 8px;
  margin-right: 16px;
  font-weight: 600;
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  color: #021426;
  border: none;
}

.inputFinal {
  height: 40px;
  padding-left: 10px;
  width: 260px;
  color: #feab3d;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  border: none;
  outline: none;
  margin-left: 8px;
}

.inputFocused {
  border: 1px solid #71aaca;
}

.inputWrapper {
  display: flex;
  min-width: 200px;
  flex: 1;
}
.flag {
  transform: translateY(-2px);
}
.chart {
  margin: 0px 10px;
  transform: translateY(3px);
}
.placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #878787;
}
