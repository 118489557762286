.main {
  overflow: auto;
  background: #fff;
  width: 90px;
  padding-top: 20px;
  left: 0;

  @media (max-width: 1300px) {
    width: 78px;
  }

  &.dm {
    box-shadow: 20px 0px 20px rgba(18, 113, 166, 0.1);
  }
}

.hidden {
  display: none;
}

.nav {
  display: flex;
  flex-direction: column;

  .navItem {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 64px;
    margin-bottom: 16px;
    position: relative;
    padding: 10px 0 6px;

    &::before {
      content: "";
      width: 4px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    span {
      font-family: "Inter";
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #404040;
      margin-top: 4px;
    }

    &.active,
    &:hover {
      background: #ecf3f7;
    }

    &.active {
      background: #ecf3f7;

      &::before {
        background: #1271a6;
      }

      span {
        color: #1271a6;
      }

      svg path {
        fill: #1271a6;
      }
    }

    &.disabled {
      pointer-events: none;
    }
  }
}

/* .menuitem {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sideicon {
  width: 18px;
  // margin-right: 32px;
}

.activeIcon {
  width: 18px;
  filter: brightness(0) invert(1);
  // margin-right: 32px;
} */

/* .sidebartitle {
  font-weight: 500;
  font-size: 12px;
}

.sidebartitleOpen {
  font-weight: 500;
}

.triggericon {
  cursor: pointer;
  position: absolute !important;
  bottom: 0 !important;
}
 */
/* .navBox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}

.navBoxOpen {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
} */
