.popover {
  padding-top: 0 !important;

  :global(.ant-popover-content) {
    margin-top: -4px !important;
  }

  :global(.ant-popover-inner-content) {
    padding: 0 !important;
  }
}
