.wrapper{
  margin-top: 20px;
  width: 100%;
  //height: 104px;
  padding: 16px 20px;
  border: 1px solid #FEAB3D;
  border-radius: 4px;
  background: white;

  p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #DB7D00;
  }
}