
.bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 11;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .wrapper {
    width: 565px;
    height: 249px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 4px;
    background: white;
    position: absolute;
    top: 20vh;
   margin: 0 auto;

    .header {
      width: 100%;
      height: 73px;
      background: linear-gradient(180deg, #1263DE 0%, #448FFF 76.56%);
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-radius: 4px 4px 0 0;
      border-bottom: 1px solid #E6E7EB;

      span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }

      .close {
        width: 36px;
        height: 36px;
        border-radius: 4px;
        border: solid 1px white;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        outline: none;
        padding: 0;
      }
    }

    .body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 30px;
      //margin: 30px;
      color: black;
      width: 100%;

      div{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: black;
      }

      input {
        width: 326px;
        height: 40px;
        background: transparent;
        border-radius: 4px;
        border: 1px solid silver;
        padding: 8px;
        color: black;
      }
    }

    .button{
      width: 100%;
      padding: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      button{
        background: #4B8121;
        padding: 8px 12px;
        color:white;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        border: none;
        border-radius: 4px;
      }
    }
  }
}
@media (max-width: 767px){
.bg{
  .wrapper {
    max-width: 343px;
    max-height: 209px;

    input {
      max-width: 290px;
    }


  }
  .body {
    padding: 0 10px 0 0;
    width: 311px;
    margin: 0 auto !important;
    display: block;
    max-height: 85px;
    div{
      font-size: 14px;
      line-height: 20px;
    }

  }
}
  .header{
    max-height: 50px;
    padding: 5px ;
  }
  .buttonMob{
    width: 100%;
    padding: 18px;
    //display: flex;
    //justify-content: flex-end;
    //align-items: flex-end;

    button{
      padding: 8px 12px;
      color:white;
      width: 100%;
      background: linear-gradient(161.19deg, #1263DE 18.35%, #448FFF 74.45%);
      font-family: 'Poppins', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      border: none;
      border-radius: 4px;
    }
  }
}
