.body{
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .scrollWrapper{
    width: 100%;
    height: 18px;
    //padding: 4px;
    border-radius: 4px;
    background: white;
    margin-top: 40px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .scroll{
      width: 200px ;
      height: 10px;
      background: #1689CA;
      border-radius: 4px;
    }
  }
}
.pading{
  width: calc(100% - 8px) ;
  border-radius: 4px;
  background: white;
  overflow: hidden;
}
