.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(31, 133, 207, 0.25);
    padding: 20px 34px;

    .back {
        margin-right: 16px;
    }
}

.footerNavigation {
    display: flex;
}