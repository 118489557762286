.decision_templates {
  width: 331px;
  height: 121px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  // padding: 10px;
  padding: 6px 10px 10px 5px;
  display: flex;
  justify-content: center;
  position: relative;
  display: flex;
  gap: 10px;
  .decision_gradient {
    width: 6px;
    height: 109px;
    background: linear-gradient(25.99deg, #7400cf 16.39%, #bc68fd 83.61%);
    border-radius: 4px;
  }
  .decision_header {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    .title {
      h5 {
        margin-bottom: 0;
      }
      a {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: #6b7ddc;
        text-decoration: none;
        position: relative;
        bottom: 7px;
      }
    }
    .preview {
      position: relative;
      bottom: 4px;
      a {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: #418db8;
        text-decoration: none;
      }
    }
  }
  .decision_addition {
    position: absolute;
    bottom: 7px;
    right: 7px;
    button {
      width: 28px;
      height: 28px;
      background: #1271a6;
      border-radius: 3px;
      display: flex;
      border: none;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 20px;
      cursor: pointer;
      span {
        transform: translateY(-2px);
      }
    }
  }
}
