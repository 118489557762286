.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1271a6;
  border-radius: 4px;
  height: 64px;
  padding: 0 20px;

  .back {
    width: 20px;
    height: 20px;
    outline: none;
    border: none;
    background: transparent;
    margin-right: 16px;
  }

  .title {
    font-family: "Inter";
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;
    margin-right: 76px;
    color: #fff;
  }

  .create {
    outline: none;
    border: none;
    background: #ff9900;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    padding: 10px 16px;
    text-transform: uppercase;
    cursor: pointer;
  }
}

.searchWrapper {
  display: flex;
  align-items: center;
  max-width: 500px;
  width: 100%;

  .icon {
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 8px;
  }
}

.search {
  flex: 1;
  font-family: "Inter";
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 1px 2px rgba(254, 230, 230, 0.05);
  border-radius: 6px;
  padding: 11px 12px 11px 36px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  &::placeholder {
    color: #a3a3a3;
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: -7px;
  // padding-left: 20px;
}
.see_more {
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #1271a6;
  cursor: pointer;
}
.centered {
  position: fixed;
  top: 55%;
  width: 25%;
  height: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
