.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1271a6;
  height: 64px;
  padding: 0 20px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  .container {
    display: flex;
    align-items: center;

    .button {
      background: transparent;
      border: none;
      outline: none;
      padding: 0;
      width: 24px;
      height: 24px;
      margin-right: 12px;
      display: flex;
      align-items: center;
    }
    .title {
      padding-left: 0.35rem;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
    }
  }
}
