.bg{
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 1001;
  width: 100%;
  height: 100%;
  top: 60px;
  left: 0;
}

.main {
  position: fixed;
  z-index: 100;
  overflow: auto;
  background: #fff;
  //filter: drop-shadow(20px 0px 20px #d7ecf8);
  width: 242px;
  padding-top: 20px;
  left: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  //&.dm {
  //  box-shadow: 20px 0px 20px rgba(18, 113, 166, 0.1);
  //}
}

.hidden {
  display: none;
}

.nav {
  display: flex;
  flex-direction: column;
.img{
  margin: 22px 26px 25px 15px;
}
  .navItem {
    text-decoration: none;
    display: flex;
    //flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 48px;
    margin-bottom: 12px;
    position: relative;
    padding: 15px;
    svg path {
      fill: #1271A6;

    }

    &::before {
      content: '';
      width: 4px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    span {
      color: #1271A6;
      margin: 4px 15px;
      font-family: 'Poppins', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }

    &.active,
    &:hover {
      background: #ECF3F7;
    }

    &.active {
      background: linear-gradient(180deg, #176089 0%, #2177A7 77.69%);;

      &::before {
        background: #1271A6;
        //background: #a61223;
      }

      span {
        color:white;

      }

      svg path {
        //fill: #1271A6;
        fill: #f8f8f8;
      }
    }
  }
}

/* .menuitem {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sideicon {
  width: 18px;
  // margin-right: 32px;
}

.activeIcon {
  width: 18px;
  filter: brightness(0) invert(1);
  // margin-right: 32px;
} */

/* .sidebartitle {
  font-weight: 500;
  font-size: 12px;
}

.sidebartitleOpen {
  font-weight: 500;
}

.triggericon {
  cursor: pointer;
  position: absolute !important;
  bottom: 0 !important;
}
 */
/* .navBox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}

.navBoxOpen {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
} */
