.bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 1400;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .wrapper {
    width: 565px;
    // height: 249px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 4px;
    background: white;
    position: absolute;
    top: 20vh;
    left: calc(50vw - 285px);

    .header {
      width: 565px;
      height: 73px;
      background: linear-gradient(180deg, #1263de 0%, #448fff 76.56%);
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-radius: 4px 4px 0 0;
      border-bottom: 1px solid #e6e7eb;

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }

      .close {
        width: 36px;
        height: 36px;
        border-radius: 4px;
        border: solid 1px white;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        outline: none;
        padding: 0;
      }
    }

    .body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 32px 20px 28px;
      color: black;
      width: 100%;

      label {
        font-family: "Inter";
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
      }

      input {
        font-family: "Inter";
        width: 108px;
        height: 40px;
        background: transparent;
        border-radius: 4px;
        border: 1px solid #e6e7eb;
        padding: 8px 12px;
        color: #000;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .footer {
      width: 100%;
      padding: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      border-top: 1px solid #e6e7eb;

      button {
        background: linear-gradient(180deg, #1263de 0%, #448fff 76.56%);
        border-radius: 4px;
        padding: 8px 12px;
        color: #fff;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        border: none;
        border-radius: 4px;
      }
    }
  }
}
