* {
  box-sizing: border-box;
}

.body {
  display: block;
  overflow: hidden;

  :global(.ant-layout-content) {
    overflow: hidden;
  }

  .mainContainer {
    max-height: calc(100vh - 50px - 64px - 80px);
    border-radius: 5px;
    padding: 20px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff !important;
      border-radius: 6px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #ccd3dc !important;
      border-radius: 6px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #ffffff !important;
    }

    .loader {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .templateContainer {
      background-color: rgb(255, 255, 255);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;

      h2 {
        display: flex;
        margin-right: auto;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #000000;
        padding-top: 10px;
      }

      .customForm {
        width: 100%;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 5px 8px;
        margin-bottom: 10px;
        // min-height: 30vh;
        // height: auto;

        .container {
          width: 100%;
          padding: 2px;

          .inputField {
            display: flex;
            flex-direction: column;

            .duration_container {
              padding-left: 8px;

              .warning {
                color: red;
              }
            }

            label {
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #737373;
            }
          }

          .inputField_collaborator {
            display: flex;
            flex-direction: column;
            width: 40%;
            margin-right: auto;

            label {
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #737373;
            }
          }

          .btnContainer {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .btnSubmit {
              outline: none;
              border: none;
              background: #ff9900;
              border-radius: 6px;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #fff;
              padding: 10px 16px;
              text-transform: uppercase;
              width: 6rem;
              display: flex;
              justify-content: center;
              cursor: pointer;
              display: flex;
              align-items: center;
            }

            :disabled {
              cursor: none;
              opacity: 0.5;
            }
          }
        }
      }

      .decisionTemplateContainer {
        background-color: rgb(255, 255, 255);
        width: 100%;
        // min-height: 40vh;
        // height: auto;

        .border_top {
          width: 100%;
          display: flex;
          margin: auto;
          // border-top: 1px solid #d9d9d9;
          padding: 8px 4px;
        }

        .container {
          // min-height: 40vh;
          // height: auto;

          padding: 0px;

          .decision_container {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;
          }
        }
      }

      .decisionTemplateContainer {
        background-color: rgb(255, 255, 255);
        width: 100%;
        // scroll-behavior: smooth;

        ::-webkit-scrollbar {
          width: 6px;
        }

        ::-webkit-scrollbar-track {
          background: #ffffff !important;
          border-radius: 6px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #ccd3dc !important;
          border-radius: 6px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #ffffff !important;
        }

        .border_top {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          .content {
            h6 {
              margin: 0;
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              color: #4b545b;
            }

            p {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #878787;
              max-width: 568px;
              flex-wrap: wrap;
              margin-bottom: 0px;
            }
          }

          .checkboxContainer {
            .checkbox {
              :global(.ant-checkbox-inner) {
                width: 20px;
                height: 20px;
                border-radius: 4px;
                transform: translateY(-20px);

                &::after {
                  left: 30.5%;
                }
              }

              :global(.ant-checkbox-checked .ant-checkbox-inner) {
                background-color: #1689ca;
                border-color: #1689ca;
              }
            }
          }
        }

        .container {
          .tableWrapper {
            .table {
              width: 100%;
              display: flex;

              &.head {
                position: sticky;
                top: -20px;
                z-index: 1;
              }

              .title {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding: 20px;
                background: #418db8;
                border-right: 1px solid #71aaca;
                height: 51px;
                position: sticky;
                top: -1px;
                z-index: 1;

                align-items: center;

                &.first {
                  border-radius: 4px 0 0 0;
                }

                &.last {
                  border-radius: 0 4px 0 0;
                  padding: 20px 16.5px;
                }

                .warning {
                  color: #ff5e5e;
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 20px;

                  &.equall {
                    color: #9bc961;
                  }
                }

                .buttonAdd {
                  background-color: transparent;
                  outline: none;
                  border: none;
                  padding: 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 0.2rem;
                  width: 115px;
                  height: 32px;
                  background: #ecf3f7;
                  border-radius: 6px;

                  svg {
                    margin-right: 5px;
                  }

                  span {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    color: #1271a6;
                  }
                }

                span {
                  font-family: "Inter";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 20px;
                  color: #ffffff;

                  div {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: #feab3d;
                  }
                }
              }

              .criteria {
                display: flex !important;
                align-items: center;
                width: 100%;

                .criteria_number {
                  width: 26px;
                  background: #418db8;
                  color: #ffffff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 50%;
                  margin-right: 5px;
                }

                span {
                  font-family: "Inter";
                  width: 24px;
                  height: 24px;
                  line-height: 24px;
                  margin-right: 4px;
                  font-weight: 600;
                  font-size: 14px;
                  color: #525252;
                }
              }

              .box {
                display: flex;
                align-items: center;
                width: 100%;

                padding: 10px 15px;
                // padding: 8px 20px;
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                background: #e7eef1;
                border-bottom: 1px #fff solid;
                border-left: 1px #fff solid;
                // background: #e3e5e9;

                height: 100%;

                .define_rubric_btn {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 20px;
                  color: #2163a6;
                  border: none;
                  background: transparent;
                }

                // &:not(.actionsBox) {
                &.inactive {
                  opacity: 0.5;
                  pointer-events: none;
                }

                // }

                &.actionsBox {
                  // width: 84px;
                  padding: 8px 16.5px;
                }
              }

              .rubric_box {
                gap: 1rem;
              }

              .weight_box {
                padding: 20px 30px;
              }

              // }
            }
          }
        }

        .sliderWrapper {
          // margin-right: 12px;
        }

        .slider_details {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;
          margin-bottom: 0.4rem;

          p {
            margin: 0;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #171717;
          }
        }

        .sliderPercentage {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          font-family: "Inter";
          color: #1b2a3d;

          p {
            margin: 0;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #737373;
          }
        }

        .addCriteria {
          position: absolute;
          z-index: 100;
          top: 20vh;
          left: calc(20vw - 285px);
        }

        .dropdown {
          /* :global(.ant-popover) {
    transform: translateX(20px) !important;
  } */

          :global(.ant-popover-inner-content) {
            padding: 0;
          }

          :global(.ant-popover-arrow) {
            display: none;
          }
        }

        .dropdownToggle {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 28px;
          height: 28px;
          border-radius: 4px;

          &:hover {
            cursor: pointer;
            background: #d5d1d1;
          }
        }

        .settings {
          background: #fff;
          box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
          border-radius: 4px;
          display: flex;
          flex-direction: column;

          button {
            font-family: "Poppins";
            padding: 13px 20px;
            color: #4c545b;
            font-size: 12px;
            line-height: 16px;
            font-weight: 500;
            text-transform: uppercase;
            border: none;
            outline: none;
            background: transparent;
            text-align: left;

            &:first-of-type {
              border-bottom: 1px solid #e6e7eb;
            }
          }
        }

        .input,
        .textarea {
          width: 100%;
          height: 44px;
          background: #e7eef1;
          border: 1px solid rgba(6, 32, 58, 0.14);
          border-radius: 6px;
          padding: 6px 12px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
        }

        .textarea {
          min-height: 52px;
          max-height: 154px;
        }

        .weightInput {
          width: 60px;
          border: 1px #00d0ff solid;
          border-radius: 4px;
        }
      }
    }

    .footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 20px 10px;
      background-color: #fff;
      position: sticky;
      bottom: -20px;
      z-index: 9;

      .addOptionContainer {
        width: 16.5rem;
        display: flex;
        justify-content: space-between;

        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          display: flex;
          align-items: center;
          color: #737373;
          margin-right: 10px;
        }

        .addBtn {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #ffffff;
          background-color: #1271a6;
          border: none;
          border-radius: 5px;
          padding: 10px 16px;
          width: 52px;
          height: auto;

          svg {
            path {
              fill: rgb(255, 255, 255);
            }
          }
        }

        input {
          width: 77px;
          height: 44px;
          background-color: white;
          border: none;
          border-radius: 6px;
          padding: 10px;
          border: 1px solid #e5e5e5;
        }
      }

      button {
        background: #ff9900;
        border-radius: 4px;
        padding: 8px 12px;
        outline: none;
        border: none;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;

        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }
}

.settings {
  background: #fff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 192px;
  height: auto;
  padding: 8px 0;

  button {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #a3a3a3;
    padding: 13px 20px;
    border: none;
    outline: none;
    background: transparent;
    text-align: left;
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 50px;

    &:not(:disabled):hover {
      background-color: #ebebeb;
    }

    .checkbox {
      :global(.ant-checkbox-inner) {
        width: 20px;
        height: 20px;
        border-radius: 4px;
        transform: translateY(-20px);

        &::after {
          left: 30.5%;
        }
      }

      :global(.ant-checkbox-checked .ant-checkbox-inner) {
        background-color: #1689ca;
        border-color: #1689ca;
      }
    }
  }

  :hover {
    color: #646262;
  }

  .bar {
    width: 90%;
    display: flex;
    margin: auto;
    padding: 0.5px;
    background-color: #e6e7eb;
  }

  .delete {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #f00000;
    margin: 0px;
    padding: 13px 20px;
  }

  .aiassistant {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.generateWithAI {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 5px;
  cursor: pointer;
  text-align: right;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #1271a6;
}