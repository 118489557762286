.background,
.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.background {
  z-index: 10000;
  background-color: rgba(#000000, 0.5);
  height: 100vh;
  user-select: none;
}

.modal {
  position: fixed;
  display: flex;
  // z-index: 100;
  z-index: 99999;
  overflow-y: auto;
  padding: 36px 0;
}

.wrapper {
  width: 100%;
  max-width: 535px;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: auto;

  .header {
    width: 100%;
    height: 55px;
    border-bottom: 1px solid #efecec;
    // background: linear-gradient(180deg, #1263de 0%, #448fff 76.56%);
    background-color: #ffffff;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    // border-radius: 4px 4px 0 0;
    .heading {
      display: flex;
      align-items: center;
      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 16px;
        color: #06203a;
      }
      .beta {
        margin-left: 5px;
        margin-top: 5px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #00c5db;
      }
    }

    .close {
      width: 28px;
      height: 28px;
      border: 1px solid #fff;
      background: #ecf3f7;
      border-radius: 2px;
      padding: 8px;
      outline: none;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      // &:hover {
      //   background-color: rgba(#fff, 0.25);
      // }
    }
  }

  .body {
    padding: 25px 20px;
    width: 100%;
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 19px;
      line-height: 10px;
      color: #06203a;
    }
    .suggestText {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #737373;
    }
    // textarea {
    //   resize: none;
    //   width: 100%;
    //   height: 80px;
    //   background: #ffffff;
    //   border: 1px solid #e5e5e5;
    //   box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    //   border-radius: 6px;
    //   padding: 10px;
    //   font-family: "Inter";
    //   font-style: normal;
    //   font-weight: 400;
    //   font-size: 14px;
    //   line-height: 20px;
    //   color: #878787;
    // }
    // .bodyCriteria {
    //   padding: 25px 20px;
    //   border-radius: 5px;
    //   border: 1px solid #e6e6e6;
    //   width: 100%;
    //   p {
    //     font-family: "Inter";
    //     font-style: normal;
    //     font-weight: 600;
    //     font-size: 19px;
    //     line-height: 10px;
    //     color: #06203a;
    //   }
    // .suggestText {
    //   font-family: "Inter";
    //   font-style: normal;
    //   font-weight: 500;
    //   font-size: 14px;
    //   line-height: 20px;
    //   color: #737373;
    // }
    textarea {
      // resize: none;
      width: 100%;
      min-height: 80px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 6px;
      padding: 10px;
      font-family: "Inter";
      font-style: italic;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #06203a;
    }
  }
}
.selectInput {
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  padding: 0;
  svg {
    width: 25px;
    height: 25px;
  }
}

.inputField {
  display: flex;
  flex-direction: column;

  .duration_container {
    padding-left: 8px;
    .warning {
      color: red;
    }
  }
  label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #737373;
  }
}

.suggest {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  padding: 12px 20px;
  width: 100px;
  height: 45px;
  border-radius: 6px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00c5db !important;
}

// .descriptionBox {
//   textarea {
//     resize: none;
//     width: 100%;
//     height: 80px;
//     background: #ffffff;
//     border: 1px solid #e5e5e5;
//     box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
//     border-radius: 6px;
//     padding: 10px;
//     font-family: "Inter";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 14px;
//     line-height: 20px;
//     color: #878787;
//   }
// }

.main {
  margin-top: 10px;
  .pros {
    background-color: #89c740;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #f5f5f5;
    border-radius: 4px 0px 0px 0px;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 8px;
    gap: 4px;
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      margin-left: 5px;
      color: #ffffff;
    }
  }
  .cons {
    background: #ff8071;
    display: flex;
    border-width: 1px 1px 0px 0px;
    border-style: solid;
    border-color: #f5f5f5;
    border-radius: 0px;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px 10px 8px;
    gap: 4px;
    span {
      font-family: "Inter";
      margin-left: 5px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #ffffff;
    }
  }
}

.greyBox {
  padding: 19px 10px;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #f4f4f4;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #f5f5f5;
  border-radius: 0px 0px 0px 8px;
  .emptyText {
    margin: 20px;
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #879091;
  }
}

.boxRight {
  border-left: 1px solid #fff;
}

.checkboxDiv {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  margin-top: 10px;
  .checkbox {
    margin-right: 10px;
    margin-top: -18px;
  }
  div {
    font-family: "Inter";
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #404040;
  }
}

.checkboxDiv1 {
  display: flex;
  background-color: #f4f4f4;
  padding: 10px 15px;
  cursor: pointer;
  width: fit-content;
  align-items: flex-start;
  margin-top: 10px;

  .checkbox {
    margin-top: -18px;
  }
  div {
    font-family: "Inter";
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #000000;
  }
}
.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  button {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    padding: 12px 20px;
    width: 55px;
    height: 36px;
    background: #1271a6;
    border-radius: 4px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .applyCriteria {
    padding: 12px 10px;
    width: 120px;
  }
  .tryagain {
    padding: 12px 10px;
    margin-right: 10px;
    color: #404040;
    width: 100px;
    background: #fff;
    border: 1px solid #e5e5e5;
  }
}
