.wrapper {
  background: linear-gradient(179.98deg, #F2F9FF 12.82%, #F8FBFF 99.98%);
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 30px;

  img {
    margin-bottom: 30px;
    width: 100%;
    max-width: 368px;
  }

  p {
    margin-bottom: 7px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #000000;
    text-align: center;
  }

  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1B2A3D;
    text-align: center;
  }
}