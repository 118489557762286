.content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 90px);
}

.wrapper {
  flex: 1;
  overflow-y: auto;
  padding-top: 24px;
  height: calc(100% - 82px);
}

.head {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 28px;
    line-height: 30px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 12px;
  }

  .text {
    font-family: 'Inter';
    font-size: 16px;
    line-height: 20px;
    color: #858E99;
    margin-bottom: 24px;
  }

  .counter {
    font-family: 'Inter';
    margin-top: 4px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: #858E99;
  }
}

.body {
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1420px;
  padding-top: 20px;
  padding-bottom: 32px;
}

.actions {
  display: flex;
  flex-direction: column;
  width: 140px;
  padding-top: 115px;

  .buttonAdd {
    margin-bottom: 24px;
  }
}

.group {
  display: flex;
  flex-direction: column;
  width: 522px;

  .toolbar {
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    &.available {
      justify-content: space-between;
    }

    &.selected {
      justify-content: flex-end;
    }

    .button {
      width: 150px;
      margin-left: 12px;
    }

    .scope {
      display: flex;
      align-items: center;

      span {
        font-family: 'Inter';
        color: #1F85CF;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        margin-right: 10px;
      }

      select {
        font-family: 'Inter';
        color: #1B2A3D;
        background: transparent;
        border: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        height: 20px;
        padding-right: 6px;
        position: relative;
        top: -1px;
        outline: none;
      }
    }
  }

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 24px;
    color: #112538;
  }

  .list {
    flex: 1;

    .droppable {
      height: 100%;
      transition: background-color .3s;

      &.over {
        background-color: rgba(#fff, 0.35);
      }
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(31, 133, 207, 0.25);
  padding: 20px 34px;

  .back {
    margin-right: 16px;
  }
}

.createArea {
  transition: height .3s, opacity .3s, visibility .3s;
  overflow: hidden;
  height: 0;
  opacity: 0;
  visibility: hidden;

  .createAreaContent {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 4px;
    margin-bottom: 4px;
    padding: 11px 24px;
    height: 46px;
    justify-content: space-between;

    button{
      height: 15px;
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    input {
      font-family: "Inter";
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #112538;
      border: none;
    }
  }

  &.active {
    height: 50px;
    opacity: 1;
    visibility: visible;
  }
}
