.button {
  font-family: 'Inter';
  text-transform: uppercase;
  text-align: center;
  background: #1F85CF;
  border: 1px solid #1F85CF;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  padding: 12px 16.5px;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;

  &:hover {
    color: #fff;
  }

  &:disabled,
  &[disabled] {
    color: #fff;
    opacity: .5;
    pointer-events: none;
  }

  &.outline {
    background: transparent;
    border: 1px solid #1F85CF;
    color: #1F85CF;

    &:hover {
      background: #cadce9;
    }
  }

  &.link {
    background: transparent;
    text-transform: initial;
    color: #858E99;
    border: none;
  }

  &.sm {
    font-weight: 400;
    font-size: 12px;
    padding: 9px 12px;
  }

  &.icon {
    img {
      margin-right: 9px;
    }
  }
}
