.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px;

  a {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    cursor: pointer;
  }

  .left {
    display: flex;
    align-items: center;
  }

  .follow {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #1271a6;
    margin-bottom: 0;
  }

  .copyright {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #1271a6;
    margin-bottom: 0;
  }
}
@media (max-width: 1199px) {
  .footer {
    display: flex;
    flex-direction: column;
    //direction: revert;
    align-items: center;
    width: 100%;
    .left {
      display: block;
      margin-bottom: 50px;
    }
    .copyright {
      margin-bottom: 14px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      color: #1271a6;
    }
  }
}

.footer_dark {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px;
  background-color: #04192e;
  height: 48px;
  position: fixed;
  width: calc(100% - 90px);
  bottom: 0;
  right: 0;

  @media (max-width: 1300px) {
    width: calc(100% - 78px);
    height: 36px;
    padding: 18px 20px;
  }

  a {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    cursor: pointer;

    svg {
      path {
        fill: white;
      }
    }
  }

  .left {
    display: flex;
    align-items: center;
  }

  .follow {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    margin-bottom: 0;
  }

  .copyright {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    margin-bottom: 0;
  }
}
@media (max-width: 1199px) {
  .footer_dark {
    display: flex;
    flex-direction: column;
    //direction: revert;
    align-items: center;
    width: 100%;
    .left {
      display: block;
      margin-bottom: 50px;
    }
    .copyright {
      margin-bottom: 14px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      color: #ffffff;
    }
  }
}
