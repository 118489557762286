.goal{
  width: 417px;
  height: 186px;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 25px 30px;
  justify-content: space-between;
  border-radius: 5px;
  margin: 10px;
  .header{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .title{
      font-family: 'Poppins', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
    }
  }
  .progresBar{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    .name{
      font-family: 'Inter', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
    }
  }
  .footer{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .update{
      font-family: 'Inter', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
    }
    .time{
      font-family: 'Inter', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: red;
    }
  }
}