.header {
  display: flex;
  flex-direction: column;
  background: #1271A6;
  border-radius: 4px;
  margin-bottom: 12px;
  color: #fff;
  padding: 0 42px 0 16px;
  overflow: hidden;
  max-height: 60px;
  transition: max-height .3s;
  position: relative;

  .row {
    display: flex;

    &.main {
      padding: 10px 0 6px;
      height: 122px;
      border-bottom: 1px solid #418DB8;
    }
  }

  .col {
    &.border {
      border-right: 1px solid transparent;
    }

    &.grow {
      flex: 1;
    }
  }

  &.active {
    max-height: 164px;
    padding-right: 16px;

    /* .col {
      &.border {
        border-right-color: #418DB8
      }
    } */
  }

  .title {
    display: flex;
    padding-right: 24px;

    .back {
      background-color: transparent;
      border: none;
      padding: 0;
      width: 20px;
      height: 20px;
      margin-right: 12px;
      text-align: center;
    }

    span {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px;
      color: #fff;
    }

    .settings {
      background-color: transparent;
      border: none;
      padding: 0;
      width: 20px;
      height: 20px;
      margin-left: 12px;
      cursor: pointer;
    }
  }

  .deadline {
    margin-left: 28px;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    span {
      &:first-of-type {
        font-family: 'Inter';
        font-size: 14px;
        line-height: 20px;
        margin-right: 4px;
      }

      &:last-of-type {
        font-family: 'Inter';
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .objective {
    padding: 0 24px;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    .objectiveHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;

      .objectiveButtons {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 16px;
      }

      button {
        background-color: transparent;
        border: none;
        padding: 0;
        font-family: 'Inter';
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #71AACA;

        &.actionBtn {
          svg {
            margin-right: 6px;
          }
        }

        &.active {
          font-weight: 600;
          color: #fff;
        }
      }
    }

    textarea {
      width: 100%;
      height: 60px;
      font-family: 'Inter';
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      margin-bottom: 0;
      resize: none;
      border: none;
      background-color: transparent;
      padding: 0;

      &:not(:read-only) {
        background-color: #fff;
        color: #171717;
        box-shadow: 0px 0px 0px 4px #D0E3ED;
        padding: 8px 12px;
        margin-top: 10px;
      }
    }
  }

  .progress {
    padding-left: 24px;

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 6px;
      color: #fff;
      opacity: 0;
      overflow: hidden;
      visibility: hidden;
      max-height: 0;
      margin-bottom: 0;
      transition: all .3s;
    }

    &.active {
      p {
        opacity: 1;
        visibility: visible;
        max-height: 100%;
        margin-bottom: 6px;
      }
    }

    .progressBox {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .progressWrap {
        display: flex;
        align-items: center;

        &:not(:last-of-type) {
          margin-right: 8px;
        }

        span {
          font-family: 'Inter';
          font-size: 12px;
          line-height: 14px;
          margin-left: 8px;
        }
      }
    }
  }

  .nav {
    align-items: center;
    justify-content: space-between;

    .navWrapper {
      display: flex;
      align-items: center;
    }

    .navItem {
      font-family: 'Inter';
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      padding: 12px 24px;
      border-bottom: 3px solid transparent;
      text-decoration: none;
      user-select: none;

      &:hover {
        border-bottom-color: #fff;
      }

      &.active {
        font-weight: 600;
        color: #FEAB3D;
        border-bottom-color: #FEAB3D;
      }

      &.disabled {
        pointer-events: none;
      }
    }

    .button {
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      font-family: 'Inter';
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #fff;
      text-transform: uppercase;
      margin-right: 24px;

      svg {
        margin-right: 6px;
      }
    }

    .toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: none;
      padding: 0;
      width: 20px;
      height: 20px;
      position: relative;
      transform: rotate(-90deg);
      position: absolute;
      bottom: 10px;
      right: 14px;

      &.active {
        transform: rotate(90deg);
        bottom: 13px;
      }
    }
  }
}
