// .bg {
//   display: flex;
//   justify-content: center;
//   //align-items: start;
//   background: rgba(0, 0, 0, 0.6);
//   position: fixed;
//   z-index: 1001;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   overflow-y: scroll;
//   //display: none;

//   .wrapper {
//     width: 750px;
//     //height: 300px;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     align-items: flex-start;
//     border-radius: 4px;
//     border: 1px solid #e6e7eb;
//     background: white;
//     position: absolute;
//     top: 10vh;
//     left: calc(50vw - 285px);
//     background: white;
//     //overflow-x: hidden;
//     padding-bottom: 20px;
//     //box-shadow: 0 1px 3px 1px #c9c9c9;

//     hr {
//       height: 1px;
//       background: #e3e5e9;
//       width: 100%;
//     }
//     .compareWrapper {
//       width: 100%;
//       padding: 4% 4% 15px;
//     }
//     .button {
//       width: 100%;
//       padding: 10px;
//       display: flex;
//       justify-content: flex-end;
//       align-items: flex-end;

//       button {
//         background: linear-gradient(180deg, #1263de 0%, #448fff 76.56%);
//         border-radius: 4px;
//         color: white;
//         cursor: pointer;
//         font-family: "Poppins";
//         font-style: normal;
//         font-weight: 600;
//         font-size: 14px;
//         line-height: 20px;
//         border: none;
//         width: 68px;
//         height: 36px;
//         &:hover {
//           box-shadow: silver 0 0 2px 2px;
//         }
//         //background: #4B8121;
//         //padding: 8px 12px;
//         //color: white;
//         //font-family: 'Poppins';
//         //font-style: normal;
//         //font-weight: 600;
//         //font-size: 14px;
//         //line-height: 20px;
//         //border: none;
//         //border-radius: 4px;
//       }
//     }
//     .header {
//       width: 100%;
//       height: 73px;
//       background: linear-gradient(180deg, #1263de 0%, #448fff 76.56%);
//       color: white;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       padding: 20px;
//       border-radius: 4px 4px 0 0;
//       border-bottom: 1px solid #e6e7eb;

//       span {
//         font-family: "Inter";
//         font-style: normal;
//         font-weight: 600;
//         font-size: 20px;
//         line-height: 20px;
//         max-width: 600px;
//         white-space: nowrap;
//         text-overflow: ellipsis;
//         overflow: hidden;
//       }

//       .close {
//         width: 35px;
//         height: 35px;
//         border-radius: 3px;
//         border: solid 1px white;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         cursor: pointer;
//         background-color: transparent;
//       }
//     }

//     .body {
//       display: flex;
//       flex-direction: column;
//       justify-content: space-between;
//       align-items: center;
//       padding: 30px;
//       color: black;
//       width: 100%;
//       .button {
//         width: 100%;
//         padding: 20px;
//         display: flex;
//         justify-content: flex-end;
//         align-items: flex-end;

//         button {
//           background: #4b8121;
//           padding: 8px 12px;
//           color: white;
//           font-family: "Poppins";
//           font-style: normal;
//           font-weight: 600;
//           font-size: 14px;
//           line-height: 20px;
//           border: none;
//           border-radius: 4px;
//         }
//       }

//       textarea {
//         font-family: "Inter";
//         font-style: normal;
//         font-weight: 400;
//         font-size: 14px;
//         line-height: 20px;
//         padding: 12px;
//         width: 100%;
//         border-radius: 4px;
//         border: 1px solid #e3e5e9;
//         width: 100%;
//         min-height: 120px;

//         &::placeholder {
//           color: #c4c4c4;
//         }
//       }
//     }
//   }
// }

.create_option_criteria_container {
  width: 100%;
  height: 100%;
}

.drawer_header {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  .nav_btns {
    display: flex;
    align-items: center;
    gap: 1rem;
    .arrow_btn {
      width: 28px;
      height: 28px;
      background: #ecf3f7;
      border-radius: 6px;
      padding: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;
    }
    button:disabled,
    button[disabled] {
      background-color: #efefef;
      opacity: 0.5;
    }
    h5 {
      margin: 0;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 16px;
      color: #06203a;
    }
  }
  .close_btn {
    width: 28px;
    height: 28px;
    background: #ecf3f7;
    border-radius: 2px;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.drawer_body {
  width: 100%;
  height: auto;
  padding: 1rem;
  .criteria_container {
    width: 100%;
    height: auto;
    .title_container {
      width: 100%;
      span {
        display: block;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #112538;
        margin-bottom: 0.3rem;
      }
      .title {
        width: 100%;
        min-height: 52px;
        height: auto;
        padding: 5px 1rem;
        display: flex;
        align-items: center;
        background: #ecf3f6;
        border-radius: 4px;

        h5 {
          margin: 0;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          color: #171717;
          word-break: break-all;
        }
      }
    }
    .score_and_weight_container {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      padding: 1.5rem 0;
      .score_container {
        display: flex;
        align-items: center;
        gap: 1rem;
        p {
          margin: 0;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #112538;
        }
        .score {
          width: 36px;
          height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2px;
          background: #ffffff;
          border: 2px solid #e3e5e9;
          border-radius: 2px;
          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
          }
        }
      }
      .weight_container {
        display: flex;
        align-items: center;
        gap: 1rem;
        p {
          margin: 0;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #112538;
        }
        .weight {
          width: 39px;
          height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2px;
          background: #418db8;
          border-radius: 4px;

          span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #fafafa;
          }
        }
      }
    }
  }
  .pros_and_cons_container {
    width: 100%;
    // height: 380px;
    max-height: 380px;
    overflow-y: scroll;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #f5f5f5;
    border-radius: 6px;
    .pros_container {
      .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        padding: 10px 10px 10px 16px;
        height: 52px;
        background: #89c740;
        border-right: 1px solid #f5f5f5;
        border-radius: 6px 0 0 0;
        h5 {
          margin: 0;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
        }
        button {
          width: 92px;
          height: 32px;
          padding: 6px 12px;
          background: #d4f8ab;
          border-radius: 6px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: #458c0a;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.4rem;
          border: none;
        }
      }
      .body {
        .details {
          width: 100%;
          height: auto;
          padding: 10px 10px 10px 16px;
          background: #ffffff;
          border-width: 0px 1px 1px 0;
          border-style: solid;
          border-color: #f5f5f5;
          display: flex;
          align-items: flex-start;
          gap: 10px;
          .index {
            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #a3a3a3;
            }
          }
          .description {
            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #404040;
            }
          }
        }
        .btn_container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .add_btn {
            gap: 0.5rem;
            display: flex;
            align-items: center;
            border: none;
            background: transparent;
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #89c740;
            padding: 0.8rem 1rem;
          }
        }
      }
    }
    .cons_container {
      .header {
        width: 100%;
        height: 52px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        padding: 10px 10px 10px 16px;
        background: #ff8071;
        border-radius: 0 6px 0 0;
        h5 {
          margin: 0;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
        }
        button {
          width: 96px;
          height: 32px;
          padding: 6px 12px;
          background: #ffebdc;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.4rem;
          border: none;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: #ff8071;
        }
      }
      .body {
        .details {
          width: 100%;
          height: auto;
          padding: 10px 10px 10px 16px;
          background: #ffffff;
          border-width: 0px 0 1px 0px;
          border-style: solid;
          border-color: #f5f5f5;
          display: flex;
          align-items: flex-start;
          gap: 10px;
          .index {
            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #a3a3a3;
            }
          }
          .description {
            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #404040;
            }
          }
        }
        .btn_container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .add_btn {
            gap: 0.5rem;
            display: flex;
            align-items: center;
            border: none;
            background: transparent;
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #ff5e5e;
            padding: 0.8rem 1rem;
          }
        }
      }
    }
  }
  .notes_container {
    width: 100%;
    height: auto;
    margin-top: 2rem;

    label {
      display: block;
      margin-bottom: 0.6rem;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #06203a;
    }
    .generateWithAI {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 5px;
      cursor: pointer;
      text-align: right;
      font-feature-settings: "tnum" on, "lnum" on;
      color: #1271a6;
      margin: 0;
    }
    textarea {
      width: 100%;
      min-height: 104px;
      padding: 12px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 6px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #112538;
    }
  }
  .button {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    button {
      background: #1271a6;
      padding: 8px 12px;
      color: white;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:disabled {
        opacity: 0.5;
      }
    }
  }
}
