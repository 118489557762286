.wrapper {
  padding: 0 20px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 20px 20px;
}

.weightDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.weightSum {
  font-weight: 600;
  // width: 50%;
  font-size: 16px;
  line-height: 20px;

  &.red {
    color: #ff5e5e;
  }

  &.green {
    color: #9bc961;
  }
}
