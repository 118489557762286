.card {
  width: calc(25% - 14px);
  padding: 20px 30px;
  background: #FFFFFF;
  border-radius: 4px;
  margin: 7px;
  cursor: pointer;
  transition: box-shadow .3s;
  
  .menuDots{
    width: 20px;
    height: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-right: -6px;
  }

  @media (max-width: 1520px) {
    width: calc(33.33% - 14px);
  }

  &:hover {
    box-shadow: rgb(148 182 202) 0px -2px 12px;
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .title {
      color: #112538;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .progressWrapper {
    margin-bottom: 9px;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2px;

      .title {
        font-family: 'Inter';
        font-size: 12px;
        line-height: 24px;
        color: #06203A;
      }

      .text {
        font-family: 'Inter';
        font-size: 12px;
        line-height: 24px;
        color: #858E99;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;

    .update {
      color: #858E99;
      font-family: 'Inter';
      font-weight: 400;
      font-size: 11px;
      line-height: 15px;
    }

    .deadline {
      font-family: 'Inter';
      font-weight: 500;
      font-size: 11px;
      line-height: 15px;
      color: #3090F1;
      text-transform: capitalize;
    }
  }
}
