.content {
  display: flex;
}

.menuDots {
  width: 20px;
  cursor: pointer;
  margin-right: -6px;
}

.form {
  flex: 1;
  padding: 30px;
  background: #ffffff;
  max-width: 1000px;
  margin-right: 30px;

  .inputGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .label {
      font-family: "Roboto";
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
      color: #1b2a3d;
    }
    .error {
      font-family: "Roboto";
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 4px;
      margin-top: 5px;
      color: #ff0000;
    }

    .input {
      font-family: "Roboto";
      border: 1px solid #e3e5e9;
      border-radius: 4px;
      font-size: 14px;
      line-height: 20px;
      padding: 10px 12px;

      &::placeholder {
        color: #8e97a3;
      }
    }
  }
}

.sidebar {
  max-width: 380px;
  flex: 1;
  max-height: 600px;
  overflow: auto;
}

.note {
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 20px 18px 20px;
  max-width: 380px;
  margin-bottom: 12px;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;

    h4 {
      font-weight: 300;
      font-size: 18px;
      line-height: 28px;
      color: #112538;
      margin-bottom: 0;
    }
  }

  span {
    display: block;
    font-family: "Inter";
    font-size: 14px;
    line-height: 20px;
    color: #798b99;
    margin-bottom: 4px;
  }

  p {
    color: #112538;
    font-size: 14px;
    line-height: 20px;
    font-family: "Inter";
    margin-bottom: 0;
  }
}

.addNote {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 4px;
  padding: 12px;
  cursor: pointer;
  max-width: 380px;

  span {
    width: 20px;
    color: #1f85cf;
  }

  p {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1f85cf;
    margin-left: 4px;
    margin-bottom: 0;
  }
}
