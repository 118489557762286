.wrapper {
  height: 50px;

  .addCategory {
    margin-left: 10px;
    color: #1F85CF;
    font-size: 14px;
    font-weight: 500;
  }

  .content {
    display: flex;
    align-items: center;
    height: 46px;
    background: #FFFFFF;
    border-radius: 4px;
    margin-bottom: 4px;
    padding: 11px 24px;
    justify-content: space-between;

    img {
      margin-right: 24px;
    }

    .checkbox {
      margin-right: 20px;
    }

    .weight {
      font-family: 'Inter';
      margin-right: 24px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #858E99;
    }

    h4 {
      font-family: 'Inter';
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #112538;
      margin-bottom: 0;
    }
  }

  &.reorder {
    height: 65.5px;

    .content {
      height: 61.5px;
      width: 90%;
      display: flex;
      justify-content: space-between;
    }

    .weight {
      width: 24px;
      height: 24px;
      line-height: 24px;
      color: #fff;
      text-align: center;
      background: #1B2A3D;
      border-radius: 50%;
    }

    h4 {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px;
  border: 1px solid silver;
  border-radius: 4px;
  width: 100px;
  height: 80px;
  position: absolute;
  top: 25vh;
  //left: 15vw;

  z-index: 10;
  background: white;

  .item {
    width: 90px;
    padding: 5px;
    border-radius: 4px;
  }

  .item:hover {
    background: lightblue;
  }
}

.minus {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: solid 2px orange;
  color: orange;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 20px 0 0;
}
.minus:hover{
  box-shadow: 0 0 1px 2px red;
}
.addSubcategoyWrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}