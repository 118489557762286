.page {
  .content {
    background: linear-gradient(121.07deg, #F3FBFF 11.18%, #B3DCF9 97.65%);
    height: calc(100vh - 75px);

    overflow-y: auto;
  }
  //
  ///* ===== Scrollbar CSS ===== */
  ///* Firefox */
  //* {
    scrollbar-width: auto;
    scrollbar-color: #1689CA #F5F5F5;
  //}
  //
  ///* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 17px;
  }

  *::-webkit-scrollbar-track {
    background: #F9F9F9;
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb {
    border: 3px solid #F9F9F9;
    background: #1689CA;
    border-radius: 10px;
  }

}
