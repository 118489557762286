.wrapper {
  width: 100%;
  padding: 12px 16px 8px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #E6E7EB;
  background: white;

  .name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    overflow: hidden;
    word-break: break-word;
  }

  .score {
    width: 40px;
    .dropdownCustom {
      background:transparent;
    }

  }
}


.menu{
  width: 60px;
}
.items{
  width: 60px;
  height: 36px;
  padding: 10px 20px;
  border-bottom: 1px solid #e7e7e7;
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #A3A3A3;
}

//    position: relative;
//    svg path{
//      fill: #000000;
//    }
//    &.active{
//      transform: rotate(45deg);
//    }
//  }
//}
//.items{
//  position:absolute ;
//}
