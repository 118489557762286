.cardWrapper {
  width: 552px;
  height: 196px;
  border-radius: 4px;
  padding: 12px;
  background: white;
  margin: 12px 20px 0 0;
  transition: box-shadow 0.3s;
  position: relative;

  .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .dropdownWrapper {
    position: absolute;
    width: 42px;
    height: 42px;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    box-shadow: rgb(148 182 202) 0px -2px 12px;
  }

  .img {
    img {
      width: 171px;
      height: 171px;
      border-radius: 4px;
      background: #a2a4a6;
      object-fit: cover;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 180px;
    align-items: flex-start;
    width: 317px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;

      .popover {
        z-index: 999;
        font-size: 24px;
        color: #858e99;
        transform: translate(2px, -6px);
      }

      .title {
        padding-top: 4px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        overflow: hidden;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        display: -webkit-inline-box;
        vertical-align: middle;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .priority {
      border-radius: 4px;
      padding: 5px 10px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;

      &.high {
        background: rgba(255, 0, 0, 0.1);
        color: #ff0000;
      }

      &.medium {
        background: rgba(254, 171, 61, 0.15);
        color: #feab3d;
      }

      &.low {
        background: #e7f6f8;
        color: #10a3b7;
      }
    }

    .readOnly {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: #737373;
      text-transform: uppercase;
    }

    .progressWrapper {
      display: flex;
      flex-direction: column;
      height: 20px;
      justify-content: center;
      margin: 8px 0;

      .progress {
        display: flex;
        justify-content: space-between;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
      }
    }

    .footer {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #feab3d;
    }
  }
}

.deadline {
  color: #3090f1;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 16px;

  span {
    color: #656565;
    font-weight: 400;
  }
}

.winner {
  display: flex;
  align-items: center;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #feab3d;
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    // display: -webkit-inline-box;
    vertical-align: middle;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    flex: 1;
    max-width: 270px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.menu {
  padding: 0;
  height: 70px;

  div {
    margin: 10px 0;
    cursor: pointer;
    width: 100%;
    padding: 0 10px;
    border-radius: 4px;

    &:hover {
      background: #d7d7d7;
    }
  }
}

@media (max-width: 1300px) {
  .cardWrapper {
    width: 343px;
    height: 133px;
    border-radius: 4px;
    padding: 8px;
    margin: 10px 8px 0 0;
    .img {
      img {
        width: 117px;
        height: 117px;
        border-radius: 4px;
        background: #a2a4a6;
        object-fit: cover;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 117px;
      align-items: flex-start;
      width: 202px;
      padding: 0 0 0 0;
      margin: 0 0 0 0;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .popover {
          width: 30px;
          position: relative;
          bottom: 10px;
          padding: 10px 8px 24px 8px;
        }

        .title {
          font-family: "Poppins", serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
        }

        span {
          padding: 0 0 0 0;
          margin: 0 0 0 0;
          height: 10px;
        }
      }

      .priority {
        border-radius: 4px;
        padding: 5px 10px;
        font-family: "Inter", serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;

        &.high {
          background: rgba(255, 0, 0, 0.1);
          color: #ff0000;
        }

        &.medium {
          background: rgba(254, 171, 61, 0.15);
          color: #feab3d;
        }

        &.low {
          background: #e7f6f8;
          color: #10a3b7;
        }
      }

      .footer {
        font-size: 12px;
        line-height: 15px;
        color: #feab3d;
      }
    }
  }

  .deadline {
    font-size: 12px;
    line-height: 15px;
    margin: 0 0 4px 0;

    span {
      color: #656565;
    }
  }

  .winner {
    display: flex;
    align-items: center;
    height: 10px;
    padding: 0 0 0 0;

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #feab3d;
      margin-left: 8px;
      max-width: 170px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .menu {
    padding: 0;
    height: 70px;

    div {
      margin: 10px 0;
      cursor: pointer;
      width: 100%;
      padding: 0 10px;
      border-radius: 4px;

      &:hover {
        background: #d7d7d7;
      }
    }
  }
}
