.background,
.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.background {
  z-index: 88;
  background-color: rgba(#000000, 0.5);
  height: 100vh;
}

.modal {
  position: fixed;
  display: flex;
  z-index: 90;
  overflow-y: auto;
  padding: 36px 0;
}

.wrapper {
  width: 100%;
  max-width: 744px;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: auto;

  .header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 73px;
    background: #1271a6;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-radius: 4px 4px 0 0;

    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: white;
    }
  }

  .body {
    // display: flex;
    padding: 20px 20px 10px 20px;
    width: 100%;
    .title_container {
      width: 100%;
      display: flex;
      gap: 10px;
      .gradient {
        width: 6px;
        height: 36px;
        background: linear-gradient(44.15deg, #107d1b 14.72%, #50d35d 85.77%);
        border-radius: 4px;
      }
      h5 {
        margin-bottom: 0;
      }
      a {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: #6b7ddc;
        position: relative;
        bottom: 7px;
        text-decoration: none;
      }
    }
    .description {
      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
      }
    }
    .criteria_container {
      width: 100%;
      height: auto;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      //   padding: 15px 279px 16px 16px;
      padding: 15px 15px 16px 16px;
      h6 {
        margin-bottom: 5px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #4b545b;
      }
      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #878787;
      }
      .criteria_checkboxes {
        width: 100%;
        height: auto;
        max-width: 70%;
        .checkbox {
          //   width: 133px;
          width: fit-content;
          //   height: 44px;
          background: #f4f4f4;
          border-radius: 4px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 20px;
          padding: 10px;
          margin-bottom: 3px;
          margin-right: 10px;
          p {
            margin-bottom: 0;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
            word-break: break-all;
          }
        }
      }
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 20px 20px 20px;

    button {
      //   background: linear-gradient(180deg, #1263de 0%, #448fff 76.56%);
      //   border-radius: 4px;
      //   padding: 8px 12px;
      //   color: white;
      //   font-family: "Poppins";
      //   font-style: normal;
      //   font-weight: 600;
      //   font-size: 12px;
      //   line-height: 20px;
      //   border: none;
      //   text-transform: uppercase;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      padding: 12px 20px;
      width: 210px;
      height: 48px;
      background: #1271a6;
      border-radius: 4px;
      border: none;

      &:disabled {
        opacity: 0.5;
      }
    }
  }
}

.box {
  padding: 16px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  height: 58px;
  border-bottom: 1px solid #e6e7eb;
  border-right: 1px solid #e6e7eb;

  &.winner {
    background: linear-gradient(
        0deg,
        rgba(254, 171, 61, 0.1),
        rgba(254, 171, 61, 0.1)
      ),
      #ffffff;
    color: #feab3d;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    border-bottom: 1px solid #e6e7eb;
    border-right: 1px solid #e6e7eb;
  }
}

.title {
  background: #2d8cc1;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  width: 100%;
  color: #ffffff;
  height: 40px;
  padding: 6px 10px;
  border-right: 1px white solid;
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  padding: 0;
  svg {
    width: 25px;
    height: 25px;
  }
}
