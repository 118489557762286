.empty {
  border: 3px solid #feab3c !important;
}

.highlighted {
  background-color: #fff2d1 !important;
  border: 3px solid #feab3d !important;
}

.wrapper {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // z-index: 1;
  background: white;
  border: 3px solid #e3e5e9;
  border-radius: 3px;
  cursor: pointer;

  button {
    border: none;
    background-color: transparent;
    padding: 0;
  }

  .value {
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 10px;
    width: 100%;
    height: 100%;

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Inter";
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      width: 36px;
      height: 40px;
      color: #112538;
      text-align: center;

      &.green {
        color: #4b8121;
      }

      &.yellow {
        color: #feab3d;
      }

      &.red {
        color: #ff0000;
      }

      &.gray {
        background-color: #e6e7eb;
      }
    }
  }

  &.secondary {
    button {
      border: none;
      background-color: transparent;
      height: 100%;
    }

    .value {
      .item {
        font-size: 14px;
        line-height: 20px;
        width: 34px;
      }
    }
  }
}

.img {
  color: silver;
  font-size: 13px;
  font-weight: bold;
}

.position {
  overflow-y: auto;
  // max-height: 680px;
  max-height: 100vh;

  .reverse {
    flex-direction: row-reverse !important;
  }

  .PropOverContainer {
    display: flex;
    min-width: 400px;
    background-color: #fff;

    .left {
      width: 70%;
      background-color: #1271a6;
      padding: 0.4rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // .wrapper {
      //   overflow-y: auto;
      //   height: 80vh;

      .cost {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem;
        color: #ffff;
        background-color: #116595;
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }
      .scoreSection {
        padding: 1rem;

        h2 {
          color: #fff;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
        }
        .Item {
          font-family: "Inter";
          padding: 0;
          margin: 0;
          margin-top: 0.5rem;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #ffffff;

          .green {
            color: #7fc55e;
          }
          .red {
            color: #ff0000;
          }
          .yellow {
            color: #feab3d;
          }
          .itemSelectRubric {
            white-space: pre-wrap;
            line-height: 40px;
            max-width: 15rem;
            width: auto;
            overflow-x: hidden;
          }
          p {
            height: 70vh;
            overflow-y: auto;
          }
        }
      }
      // }
      .leftbutton {
        width: 100%;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        .ai_icon {
          cursor: pointer;
        }
        button {
          background-color: transparent;
          border: none;
          outline: none;
          cursor: pointer;
          color: #fff;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          padding: 0;
        }
      }
    }
    .right {
      width: 30%;
      padding-top: 1rem;
      ul {
        padding: 0 1rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        padding-left: 1rem;
        gap: 0.65rem;
        width: 100%;
        .active {
          background: #ecf3f6;
        }
        li {
          cursor: pointer;
          list-style: none;
          width: 100%;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          border-radius: 4px;
          padding: 8px 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .green {
            color: #7fc55e;
          }
          .red {
            color: #ff0000;
          }
          .yellow {
            color: #feab3d;
          }
        }
        :hover {
          background: #fafafa;
        }
      }
    }
  }

  .itemWrapper {
    width: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid #e6e7eb;

    .itemSelect {
      width: 100%;
      padding: 16px 34px;
      height: 40.75px;
      border-bottom: 0.5px solid #e6e7eb;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: black;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 1px 1px silver;
      }

      &.green {
        color: #4b8121;
      }

      &.yellow {
        color: #feab3d;
      }

      &.red {
        color: #ff0000;
      }

      &.gray {
      }
    }
  }
}
.popover {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  .position {
    max-width: 86px;
    padding: 0px;
    border: 1px solid red;
  }
}
.popoverNoPadding {
  :global(.ant-popover-arrow) {
    display: none;
  }
  :global(.ant-popover-inner-content) {
    padding: 0;
    background: transparent;
    right: 40px;
  }
}
