.spanError {
  color: red;
  // position: absolute;
  // bottom: -14px;
  left: 0;
  display: block;
  // display: inline-block;
  opacity: 0.8;
  font-size: 12px;
}

.inputGroup {
  width: 100%;
  position: relative;
  //   margin-bottom: 20px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.labelDiv {
  display: flex;
  justify-content: space-between;
}

.generateWithAI {
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #1271a6;
}

.formControl {
  width: 100%;
  box-sizing: border-box;
  padding: 5px 8px;
  outline: #1f85cf;
  border: 1px solid #ced4da;
  // border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &::placeholder {
    color: #c0bfbf;
  }

  &.error {
    border: 1px solid rgb(255, 62, 62);
  }

  &:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }
}

.label {
  color: #858e99;

  span {
    opacity: 0.5;
    margin-left: 5px;
  }
}
