.content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 90px);
}

.wrapper {
  flex: 1;
  overflow-y: auto;
  padding-top: 24px;
  height: calc(100% - 82px);
}

.wrapActive {
  user-select: none;
  opacity: .5;
  pointer-events: none;
}

.head {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 28px;
    line-height: 30px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 12px;
  }

  .text {
    font-family: 'Inter';
    font-size: 16px;
    line-height: 20px;
    color: #858E99;
    margin-bottom: 44px;
  }
}

.body {
  flex: 1;
  display: flex;
  margin: 0 auto;
  padding: 0 32px 32px;


  .content {
    display: flex;
    flex-direction: column;
    margin-left: 15vw;
    align-items: flex-start;

    .appbar {
      width: 60vw;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 20px;

      .item {
        color: #1F85CF;
        margin: 0 20px 5px 0;
        font-family: 'Inter', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
        height: 32px;

        &.active {
          color: #FEAB3D;
          border-bottom: 2px solid #FEAB3D;
        }
      }

    }

    .goal {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-top: 20px;
      margin: 20px 0;
      .goals{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    .button{
      background: transparent;
      margin: 10px 0;
      color: #1F85CF;
      width: 195px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      &:hover{
        background: #e7e7e7;
        opacity: 0.7;

      }
    }

    .title {
      font-family: 'Poppins', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
    }


  }
}


  .formWrapper {
    position: absolute;
    top: 76px;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: calc(100vh - 76px);
    z-index: -100;
    overflow-y: scroll;
  }

  .areas {
    width: 360px;

    .title {
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 24px;
    }

    .area {
      font-family: 'Inter';
      padding: 20px 24px;
      font-size: 16px;
      line-height: 20px;
      border-radius: 4px;
      background: #fff;
      color: #112538;
      width: 100%;
      text-align: left;
      border: none;
      outline: none;
      margin-bottom: 4px;


      &.complite {
        background: #33be50;
        color: #fff;
        font-weight: 700;
      }

      &.active {
        background: #FEAB3D;
        color: #fff;
        font-weight: 700;
      }
    }
  }

  .group {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 760px;
    margin-left: 100px;

    .title {
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 24px;
    }

    .addCategory {
      margin-left: 10px;
      color: #1F85CF;
      font-size: 14px;
      font-weight: 500;
    }

    .list {

      .droppable {
        height: 100%;
        transition: background-color .3s;

        &.over {
          background-color: rgba(#fff, 0.35);
        }
      }
    }
  }

  .ballast {
    width: 10%;
  }

  .systemCategory {
    max-width: 760px;
    width: 100%;
    margin-bottom: 4px;
    background-color: #fff;
    padding: 20px 24px;
    border-radius: 4px;
    padding-left: 50px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #112538;
    cursor: pointer;
  }