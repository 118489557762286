.container {
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(117.83deg, red 10.85%, #b3dcf9 96.06%) !important;
}

.authcontainer {
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main {
  overflow: auto;
  display: flex;
  flex-direction: row;
}

.authMain {
  overflow: auto;
  display: flex;
  width: auto;
  height: auto;
  max-width: 900px;
  min-height: 90vh;
  background: #e7f2fa;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px 0;
}

.content {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;

  &.not_pp_profile {
    padding: 20px 40px 0;
  }

  &.dm {
    background-position: center;
    background-size: cover;
  }

  &.ms {
    padding: 0;
  }
}

.wrapper {
  flex: 1;
}

.authcontent {
  overflow: auto;
}
.footer {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1271a6;
  // width: 100%;
  display: flex;
  justify-content: center;
  margin: 15px;
}
@media (max-width: 1199px) {
  .content {
    padding: 0 0;
  }
}
