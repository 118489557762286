.container {
    display: inline-block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 16px;
    transition: .5s ease;
    font-family: "Inter";
    font-weight: 400;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        transition: .5s ease;
        width: 0;
    }

    .checkmark:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    input:checked~.checkmark:after {
        display: block;
    }

    &:hover input~.checkmark {
        outline: 0;
        box-shadow: 0 0 0 2px rgb(13 110 253 / 25%);
    }

    input:checked~.checkmark {
        background-color: #2196F3;
        border-color: #2196F3;
    }
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: transparent;
    border: 1px solid #C4C4C4;
    border-radius: 4px;

    &:after {
        content: "";
        position: absolute;
        display: none;
    }
}