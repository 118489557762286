
.wrapper {
  height: 50px;

  .content {
    display: flex;
    align-items: center;
    height: 46px;
    background: #FFFFFF;
    border-radius: 4px;
    margin-bottom: 4px;
    padding: 11px 24px;
  
    img {
      margin-right: 24px;
    }
  
    .checkbox {
      margin-right: 20px;
    }
  
    .weight {
      font-family: 'Inter';
      margin-right: 24px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #858E99;
    }

    h4 {
      font-family: 'Inter';
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #112538;
      margin-bottom: 0;
    }
  }

  &.reorder {
    height: 65.5px;

    .content {
      height: 61.5px;
    }

    .weight {
      width: 24px;
      height: 24px;
      line-height: 24px;
      color: #fff;
      text-align: center;
      background: #1B2A3D;
      border-radius: 50%;
    }

    h4 {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
