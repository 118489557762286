.radio {
  input[type=radio] {
    position: absolute;
    z-index: -1;
  }

  label {
    position: relative;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    color: #112538;
    box-shadow: 0px 4px 8px rgba(18, 113, 166, 0.15);
    border-radius: 6px;
    padding: 32px;
    font-size: 24px;
    line-height: 20px;
    cursor: pointer;
    transition: all .3s;

    &::before,
    &::after {
      content: '';
      border-radius: 50%;
      transition: all .3s;
    }

    &::before {
      width: 24px;
      height: 24px;
      border: 2px solid #C4C4C4;
      margin-right: 24px;
    }

    &::after {
      position: absolute;
      width: 16px;
      height: 16px;
      background: #C4C4C4;
      margin-left: 4px;
      opacity: 0;
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }

  input[type=radio]:checked + label {
    background: #FEAB3D;
    color: #fff;

    &::before {
      border-color: #fff;
    }

    &::after {
      background-color: #fff;
      opacity: 1;
    }
  }
}
