.mainWrapper{
position: relative;
.wrapper {
  width: 100%;
  //height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 5px 9px 5px 6px;
  border-bottom: 1px solid #E6E7EB;
  background: white;
  &.highlighted {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    //line-height: 20px;
    color: #FEAB3D;
    background: linear-gradient(0deg, rgba(254, 171, 61, 0.1), rgba(254, 171, 61, 0.1)), #FFFFFF;
  }
  .content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .img {
      width: 50px;
      height: 50px;
      border-radius: 4px;

      image {
        width: 50px;
        height: 50px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0 4px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;

      .nameWrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: 20px;
        width: 60vw;
        .input {
          margin: 0 10px;
          width: 100%;
          border-radius: 4px;
          border: #05d9d9 1px solid;
        }

        .name {
          font-family: 'Inter', serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          //line-height: 20px;
          margin-left: 7px;
          overflow: hidden;
          word-break: break-word;


          .placeholder{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            //line-height: 24px;
            color: #878787;

          }

          &.winner {
            color: #FEAB3D;
          }


        }
      }

      .scoreWrapper {
        display: flex;
        padding: 4px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        height: 14px;
        margin-bottom: 6px;
        //line-height: 17px;

        .adjusted {
          display: flex;
        }

        .weight {
          margin-left: 10px;
        }
      }

    }

  }

  .optionsWrapper {
    width: 50px;
    display: flex;
    align-items: center;

    svg path {
      //fill: #1271A6;
      fill: #C4C4C4;
    }

    .arrow {
      transform: rotate(-90deg);

      &.active {
        transform: rotate(90deg);
      }

      svg path {
        //fill: #1271A6;
        fill: #000000;
      }
    }
  }
}

}
.popoverWrapper {
  display: flex;
  flex-direction: column;
  width: 158px;
  border-radius: 6px;
  box-shadow: 0 0.5px 2px 1px #c7c7c7;
  position: absolute;
  z-index: 2;
  right: 40px;
  top: 18px;

  div {
    width: 158px;
    background: white;
    height: 39px;
    padding: 11px 12px;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    border-bottom: #d9d8d8 0.5px solid;
    cursor: pointer;

    &:hover {
      background: #a9a9a9;
    }
  }
}
