.wrapper {
  background: #FFFFFF;
  border-radius: 4px;
  width: 500px;
  height: 30px;
  padding: 3px;
  overflow: hidden;
}

.bar {
  background: #FEAB3D;
  width: 100%;
  height: 24px;
  border-radius: 4px;
  text-align: right;
  overflow: hidden;
  // padding-right: 5px;
  transition: width .3s;

  span {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #fff;
    margin-right: 5px;
  }
}