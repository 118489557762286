.content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 90px);
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-top: 24px;
  height: calc(100% - 82px);
}

.head {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 28px;
    line-height: 30px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 12px;
  }

  .text {
    font-family: 'Inter';
    font-size: 16px;
    line-height: 20px;
    color: #858E99;
    margin-bottom: 44px;
  }
}

.body {
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 760px;
  margin-bottom: 32px;
  overflow-y: auto;
  width: 100%;
  padding-right: 20px;
}

.group {
  display: flex;
  flex-direction: column;
  width: 100%;

  .list {
    flex: 1;

    .droppable {
      height: 100%;
      transition: background-color .3s;

      &.over {
        background-color: rgba(#fff, 0.35);
      }
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(31, 133, 207, 0.25);
  padding: 20px 34px;

  .back {
    margin-right: 16px;
  }
}
